import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import imgWorkCover from "../assets/image/jpg/agency/masonry/PdPmockup2.jpg";

import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">WEB DEVELOPMENT CONTRIBUTION</Text>
                <Title variant="secSm" className="my-4">
                MERN React app for Travel Activities.{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Pata de perro is a Mexican expression that refers to people who have a strong desire to travel, to leave a place, or just to be out in the street.
                </Text>
                </Col>
                <Col lg="4">
              <Row>
                <Col lg="4">
                <Link to="https://shrouded-bayou-22662.herokuapp.com/"  target="_blank"> <Button arrowRight>Live work</Button></Link>
                </Col>
              </Row>
              <Row>
                <p> </p>
              </Row>
              <Row>
                <Col lg="4">
                <Link to="https://github.com/adina-hc/pata-de-perro/"  target="_blank"> <Button arrowRight>GitHub Repo</Button></Link>
                </Col>
              </Row>
              </Col>  
            </Row>
          </Container>
        </Section>
     
       
       
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
